/* 设备指纹服务*/
import { asyncLoadFile } from '@shein/common-function'
const { PUBLIC_CDN, langPath, WEB_CLIENT, NODE_SERVER_ENV } = gbCommonInfo
const { generateConsole } = require('../../../../common/utils')

class DeviceInfp {

  AntiDevices = null
  armorToken = ''
  constructor() {
    window['_INCONF'] = {
      appName: WEB_CLIENT,
      apiHost: langPath,
      channel: 1,
      isOpenIntervalReport: true,
    }
    this.initDeviceConfig()
  }

  initDeviceConfig() {
    this.sdkBeforeLoad()
    const p = asyncLoadFile({
      label: 'script',
      attrs: {
        async: true,
        src: `${PUBLIC_CDN}/pwa_dist/libs/infp/infp.3.1.0.min.js`,
      }
    })
    // 3次重试,每次失败间隔5s
    this.retryFn(p, 3, 5000)
      .then(() => {
        this.AntiDevices = window.AntiDevices
        this.sdkStartInit()
      })
      .catch((e) => {
        console.error(e)
      })
  }

  async retryFn(fn, maxRetry, timeout) {
    return new Promise((resolve, reject) => {
      let retryCount = 0
      let timer
      const go = async () => {
        try {
          const result = await fn
          resolve(result)
        } catch (err) {
          if (retryCount < maxRetry) {
            retryCount++
            clearTimeout(timer)
            timer = setTimeout(go, timeout)
          } else {
            reject(err)
          }
        }
      }
      timer = setTimeout(go, timeout)
    })
  }

  isInSite(args) {
    const url = args && args[1] || ''
    // if (/^http(s)?:\/\/www\.srmdata.com/.test(url)) {
    //   return true
    // }
    if (!/^http(s)?:\/\//.test(url)) { // 相对路径
      return true
    }
    const regExp = new RegExp(`^http(s)?://${location.host}`)
    if (regExp.test(url)) { // 本站地址
      return true
    }
    return false
  }

  listenXhrBefore() {
    const self = this
    const origiOpen = XMLHttpRequest.prototype.open
    XMLHttpRequest.prototype.open = function () {
      let args = arguments
      origiOpen.apply(this, args)
      if (self.isInSite(args)) {
        this.setRequestHeader('armorToken', self.armorToken || '')
      }
    }
  }

  sdkStartInit() {
    // window.AntiDevices.ready && window.AntiDevices.ready && window.AntiDevices.ready(function () {
    //   try {
    //     const deviceId = window.AntiDevices.getDeviceId()
    //     if (NODE_SERVER_ENV !== 'production') {
    //       console.log('%c AntiDevices Loading Successfully %c %s ', 'background:#1F82C3;color:white;padding:4px;border-radius:4px 0 0 4px;font-size:12px', 'background:#4FCA2D;color:white;padding:4px;border-radius:0 4px 4px 0;font-size:12px', deviceId)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // })
    try {
      const armorToken = window.AntiDevices.getArmorToken()
      this.armorToken = armorToken
      this.sdkAfterLoad(armorToken)
    } catch (error) {
      console.log(error)
    }

  }

  sdkBeforeLoad() {
    // 使用本地临时数据
    const armorToken = window.localStorage && window.localStorage.getItem('armorToken') || ''
    window.__ArmorToken = armorToken
    this.armorToken = armorToken
    window.sessionStorage && window.sessionStorage.setItem('armorToken', armorToken)
    this.listenXhrBefore()
    if (NODE_SERVER_ENV !== 'production') {
      if (armorToken) {
        generateConsole('ArmorToken Cache Loading Successfully', armorToken)
      } else {
        generateConsole('ArmorToken Cache Loading Failed')
      }
    }

  }

  sdkAfterLoad(armorToken) {
    // 替换临时数据
    window.__ArmorToken = armorToken
    window.sessionStorage && window.sessionStorage.setItem('armorToken', armorToken)
    window.localStorage && window.localStorage.setItem('armorToken', armorToken)
    if (NODE_SERVER_ENV !== 'production') {
      generateConsole('ArmorToken SDK Loading Successfully', armorToken)
    }
  }

}

window._GB_DeviceDeviceInfp = new DeviceInfp()
