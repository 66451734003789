import * as Business from './business'
const { templateType, SiteUID } = gbCommonInfo

// 预链接配置
export function getOrigins() {
  let originsList = [
    '//www.google-analytics.com',
    '//recommender.scarabresearch.com',
    '//www.googletagmanager.com',
    '//connect.facebook.net',
    '//static.ads-twitter.com',
    '//bat.bing.com',
    '//static.criteo.net',
  ]
  SiteUID === 'pwus' && originsList.push(
    '//amplify.outbrain.com',
    '//snap.licdn.com',
    '//ads.nextdoor.com'
  )
  SiteUID === 'mru' && originsList.push('//mc.yandex.ru')
  SiteUID === 'mjp' && originsList.push(
    '//cdn.smartnews-ads.com',
    '//s.yimg.jp'
  )
  SiteUID === 'masia' && originsList.push('//wcs.naver.net')
  SiteUID === 'pwmx' && originsList.push('//dtm-dre.platform.hicloud.com')
  if (templateType === 'paySuccess') {
    originsList.push('//integrations.fitanalytics.com')
    SiteUID === 'pwus' && originsList.push('//s.amazon-adsystem.com')
  }
  if (['pwus', 'rwmus'].includes(SiteUID)) originsList.push('//s.pinimg.com')
  if (['pwus', 'pwuk', 'pwau', 'pwfr', 'pwde'].includes(SiteUID)) originsList.push('//s3.amazonaws.com')
  if (['pwus', 'pwfr', 'rwmus'].includes(SiteUID)) originsList.push('//sc-static.net')

  return originsList
}

export function getBusinessSources() {
  return Business || {}
}

export async function getSources(OL_MODE) {
  return OL_MODE
    ? Object.assign({}, ...await Promise.all([
      import(/* webpackChunkName: "tpm-sources-globals" */'./globals'),
      templateType === 'paySuccess' ? import(/* webpackChunkName: "tpm-sources-pay_success" */'./pay_success') : {}
    ]))
    : {} // import(/* webpackChunkName: "tpm-sources-whitelist" */'./whitelist')
}
