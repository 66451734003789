const { PUBLIC_CDN } = gbCommonInfo
export class FingerPrint {
  static name = 'FingerPrint'

  _loadSDK(params) {
    return {
      pixels: [{
        TPM_Ele_Type: 'script',
        TPM_Config: {
          CDN: true
        },
        src: `${PUBLIC_CDN}/pwa_dist/libs/devices/fpv2.7.js`,
        ...params
      }]
    }
  }
}
